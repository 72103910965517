import React from 'react';
import classnames from 'classnames';

import { Icon, Image } from 'components/ui';
import { isArrayEmpty } from 'helpers/ArrayHelpers';

import type { IOrder } from 'types';

import styles from './OrderDetailsSGR.module.scss';

interface Props {
  className?: string,
  order: IOrder
}

const OrderDetailsSGR = (props: Props) => {

  const {
    className,
    order
  } = props;

  const {
    sgrCollection
  } = order || {};

  const {
    title,
    items,
  } = sgrCollection || {};

  // Empty

  if (!sgrCollection || isArrayEmpty(items)) return null;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Title */}
      <div className={styles.title}>
        <Icon className={styles.icon} name="barcode" size={24} />
        <div className={styles.text}>{title}</div>
      </div>

      {/* Items */}
      <div className={styles.items}>
        {
          items.map((item, index) => {
            const { name, barcode, image } = item || {};

            return (
              <div
                key={`item-${index}`}
                className={styles.item}
              >
                <div className={styles.image}>
                  <Image src={image?.default} fill />
                </div>
                <div className={styles.details}>
                  <div className={styles.name}>{name}</div>
                  <div className={styles.barcode}>
                    <Icon className={styles.icon} name="barcode" size={16} />
                    {barcode}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>

    </div>
  );
};

export default OrderDetailsSGR;

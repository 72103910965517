import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button } from 'components/ui';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useMediaQuery } from 'hooks/useMediaQuery';

import OrderCancelModal from '../OrderCancelModal/OrderCancelModal';
import OrderInfoDate from '../OrderInfoDate/OrderInfoDate';
import OrderInfoDelivery from '../OrderInfoDelivery/OrderInfoDelivery';
import OrderInfoPayment from '../OrderInfoPayment/OrderInfoPayment';

import OrderInvoices from '../OrderInvoices/OrderInvoices';
import OrderRepeatModal from '../OrderRepeatModal/OrderRepeatModal';

import OrderDetailsTitle from './OrderDetailsTitle';

import styles from './OrderDetailsHeader.module.scss';

const OrderDetailsHeader = (props) => {
  const {
    className, order, onOrderCancel, onOrderRepeat
  } = props;

  const { canBeCancelled } = order || {};

  const [isCancelModalVisible, setIsCancelModalVisible] = React.useState();
  const [isRepeatModalVisible, setIsRepeatModalVisible] = React.useState();

  const analytics = useAnalytics();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const isLarge = useBreakpoint('md', 'up');
  const isSmall = useBreakpoint('sm', 'down');

  // Repeat Order

  const openRepeatModal = React.useCallback(() => {
    analytics.repeatOrder();
    setIsRepeatModalVisible(true);
  }, []);

  const closeRepeatModal = React.useCallback(() => setIsRepeatModalVisible(false), []);

  // Cancel Order

  const openCancelModal = React.useCallback(() => {
    analytics.cancelOrder();
    setIsCancelModalVisible(true);
  }, []);

  const closeCancelModal = React.useCallback(() => setIsCancelModalVisible(false), []);

  // Helpers

  const handleOrderCancelSuccess = () => {
    if (onOrderCancel) {
      onOrderCancel();
    }
  };

  const handleOrderRepeatSuccess = () => {
    if (onOrderRepeat) {
      onOrderRepeat();
    }
  };

  return (
    <div className={classnames(styles.root, className)}>

      {/* Title */}
      <div className={styles.titleContainer}>
        <OrderDetailsTitle order={order} />

        {/* Desktop - Order Cancel Button */}
        {
          !isBreakpoint && (
            canBeCancelled && (
              <div className={styles.desktopActions}>
                <OrderCancelButton onClick={openCancelModal} />
              </div>
            )
          )
        }
      </div>

      {/* Details */}
      <div className={styles.detailsContainer}>
        <div className={styles.details}>

          {/* Date */}
          <OrderInfoDate
            order={order}
            className={styles.detailsBlock}
            iconClassName={styles.detailsBlockIcon}
            iconSize="medium"
            size="medium"
            withBorder
            withIcon
            childrenPosition={isSmall || isLarge ? 'outside' : 'inside'}

          >
            <OrderRepeatButton onClick={openRepeatModal} />
          </OrderInfoDate>

          {/* Delivery */}
          <OrderInfoDelivery
            order={order}
            className={styles.detailsBlock}
            iconClassName={styles.detailsBlockIcon}
            valueClassName={styles.detailsBlockValue}
            iconSize="medium"
            size="medium"
            withBorder
            withIcon
          />

          {/* Payment */}
          <OrderInfoPayment
            order={order}
            className={styles.detailsBlock}
            iconClassName={styles.detailsBlockIcon}
            iconSize="medium"
            size="medium"
            withBorder
            withPaymentMethods
            withIcon
            childrenPosition={isSmall || isLarge ? 'outside' : 'inside'}
          >
            {
              order?.invoices?.length > 0 && (
                <OrderInvoices className={styles.actionBtn} order={order} />
              )
            }
          </OrderInfoPayment>

        </div>
      </div>

      {/* Responsive - Order Cancel Button */}
      {
        isBreakpoint && (
          <div className={classnames(styles.mobileActions, { [styles.empty]: !canBeCancelled })}>
            {
              canBeCancelled && <OrderCancelButton onClick={openCancelModal} />
            }
          </div>
        )
      }

      {/* Order Cancel Modal */}
      <OrderCancelModal
        isOpen={isCancelModalVisible}
        order={order}
        onSubmitSuccess={handleOrderCancelSuccess}
        handleClose={closeCancelModal}
      />

      {/* Order Repeat Modal */}
      <OrderRepeatModal
        isOpen={isRepeatModalVisible}
        order={order}
        onSubmitSuccess={handleOrderRepeatSuccess}
        handleClose={closeRepeatModal}
      />
    </div>
  );
};

// Buttons

const OrderRepeatButton = (props) => {
  const { className, onClick } = props;
  const { t } = useTranslation();
  return (
    <Button
      variant="primary"
      size="medium"
      className={classnames(styles.actionBtn, className)}
      onClick={onClick}
      data-testid="order-details.repeat-btn"
    >
      {t('account:ORDER_HISTORY.REPEAT_ORDER')}
    </Button>
  );
};

const OrderCancelButton = (props) => {
  const { className, onClick } = props;
  const { t } = useTranslation();
  return (
    <Button
      className={classnames(styles.actionBtn, className)}
      variant="subtle"
      startIcon="x"
      size="medium"
      onClick={onClick}
      data-testid="order-details.cancel-btn"
    >
      {t('account:ORDER_HISTORY.CANCEL_ORDER')}
    </Button>
  );
};

export default OrderDetailsHeader;

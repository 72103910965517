import React from 'react';
import { useTranslation } from 'next-i18next';

import * as Notifications from 'common/Notification';
import { Accordion, Icon, Skeleton } from 'components/ui';
import { useOrderReportProblemDispatchHelpers } from 'context/OrderReportProblemContext';
import { ORDER_PROBLEM_TYPES, getAvailableOrderProblemTypes, getProblemTypeIcon } from 'helpers/OrderHelpers';
import { useConfig } from 'hooks/data/useConfig';

import OrderProblemDelivery from './OrderProblemDelivery';
import OrderProblemIncomplete from './OrderProblemIncomplete';
import OrderProblemNavigation from './OrderProblemNavigation';
import OrderProblemReturn from './OrderProblemReturn';
import OrderProblemSummary from './OrderProblemSummary';

import styles from './OrderProblemModal.module.scss';

const OrderProblemsList = (props) => {
  const {
    order,
    closeModal
  } = props;

  // Hooks

  const { t } = useTranslation();
  const { data: config } = useConfig();

  // Context Hooks

  const { showStep } = useOrderReportProblemDispatchHelpers();

  // Props

  const { orderProblemTypes } = config || {};

  // Loading

  if (!orderProblemTypes?.length || orderProblemTypes.length === 0) {
    return (
      <Skeleton count={4} />
    );
  }

  // Props

  const availableOrderProblemTypes = getAvailableOrderProblemTypes(order, orderProblemTypes);

  // Effects

  React.useEffect(() => {
    if (availableOrderProblemTypes?.length === 1) {
      selectProblemType(availableOrderProblemTypes[0], { titleProps: { isBackVisible: false } });
    }
  }, [availableOrderProblemTypes]);

  // Helpers

  const showSelectionStep = () => {
    showStep({
      title: t('account:ORDER_HISTORY.REPORT_ISSUE_TITLE'),
      component: OrderProblemsList,
      modalProps: {
        contentClassName: styles.selectionModal
      }
    });
  };

  const showDetailsStep = (Component, problem, { titleProps = {}, stepProps = {}, modalProps = {} } = {}) => {
    showStep({
      title: OrderProblemNavigation,
      titleProps: {
        title: problem.name,
        onBackClick: showSelectionStep,
        ...titleProps
      },
      component: Component,
      componentProps: {
        problem,
        showCurrentStep: (p) => showDetailsStep(Component, problem, { stepProps: p, modalProps }),
        showSelectionStep,
        ...stepProps
      },
      modalProps
    });
  };

  const selectProblemType = (problem, { titleProps = {} } = {}) => {
    const { type } = problem;
    switch (type) {
      case ORDER_PROBLEM_TYPES.INCOMPLETE:
        showDetailsStep(
          OrderProblemIncomplete,
          problem,
          { titleProps }
        );
        break;
      case ORDER_PROBLEM_TYPES.RETURN:
        showDetailsStep(
          OrderProblemReturn,
          problem,
          { titleProps }
        );
        break;
      case ORDER_PROBLEM_TYPES.DELIVERY:
        showDetailsStep(
          OrderProblemDelivery,
          problem,
          {
            modalProps: {
              contentClassName: styles.selectionModal
            },
            titleProps
          }
        );
        break;
      case ORDER_PROBLEM_TYPES.OTHER:
        showStep({
          title: OrderProblemNavigation,
          titleProps: {
            title: t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_TITLE'),
            onBackClick: showSelectionStep,
            ...titleProps
          },
          component: OrderProblemSummary,
          componentProps: { problem }
        });
        break;
      default:
        Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'setOrderShippingError' });
        closeModal();
    }
  };

  // Render

  return (
    <div className={styles.list}>
      {
        availableOrderProblemTypes.map((problem) => {
          const { type, name } = problem;
          const icon = getProblemTypeIcon(type);
          return (
            <Accordion
              key={type}
              className={`${styles.listItem} ${styles.problemTypeItem}`}
              expandable={false}
              onClick={() => selectProblemType(problem)}
              title={(
                <div className={styles.problemType}>
                  <Icon name={icon} />
                  <span>{name}</span>
                </div>
              )}
              dataTestId={`order-problem-modal.type.${type}`}
            />
          );
        })
      }
    </div>
  );
};

export default OrderProblemsList;

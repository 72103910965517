import React from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { Head } from 'components/ui';

import { useResize } from 'hooks/useResize';

import AccountNavigation from '../AccountNavigation/AccountNavigation';
import AddressSearchModal from '../AddressSearchModal/AddressSearchModal';
import DrawerContainer from '../DrawerContainer/DrawerContainer';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ModalContainer from '../ModalContainer/ModalContainer';
import OpenInAppBanner from '../OpenInAppBanner/OpenInAppBanner';

import styles from './AccountLayout.module.scss';

const ProductModal = dynamic(() => import('../ProductModal/ProductModal'), { ssr: false });

interface Props {
  flex?: boolean,
  withPadding?: boolean,
  openInAppBanner?: {
    isVisible: boolean,
    props: React.ComponentProps<typeof OpenInAppBanner>
  },
  children?: React.ReactNode
}

const AccountLayout = (props: Props) => {
  const {
    flex = false,
    withPadding = true,
    openInAppBanner = { isVisible: true, props: {} },
    children,
  } = props;

  // Hooks

  const { ref: resizeRef, height } = useResize({ handleWidth: false });

  // Render

  return (
    <div
      className={classnames(
        styles.root,
      )}
    >
      <Head />
      <Header />
      <div
        className={classnames(
          styles.container,
        )}
      >
        <AccountNavigation
          ref={resizeRef}
        />
        <main
          className={classnames(
            styles.content,
            { [styles.padded]: withPadding },
            { [styles.flex]: flex },
          )}
          style={{
            minHeight: height
          }}
        >
          {children}
        </main>
        <DrawerContainer />
        <ModalContainer />
      </div>
      {openInAppBanner?.isVisible && <OpenInAppBanner {...openInAppBanner.props} />}
      <AddressSearchModal />
      <ProductModal />
      <Footer />
    </div>
  );
};

export default AccountLayout;

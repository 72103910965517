import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { Button, Link } from 'components/ui';

import type { DataTestId } from 'types';

import styles from './BackButton.module.scss';

interface Props extends DataTestId {
  className?: string,
  //
  href?: string,
  as?: string,
  text?: string,
  //
  onClick?: () => void
}

const BackButton = (props: Props) => {

  const {
    className,
    //
    href,
    as,
    text = 'ACTIONS.BACK',
    //
    onClick,
    //
    dataTestId
  } = props;

  const router = useRouter();
  const { t } = useTranslation();

  if (href) {
    return (
      <Link
        href={href}
        as={as}
        passHref
        prefetch={false}
      >
        <Button
          tag="a"
          className={classnames(styles.root, className)}
          iconClassName={styles.icon}
          contentClassName={styles.content}
          startIcon="arrow-narrow-left"
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          dataTestId={dataTestId}
        >
          {t(text)}
        </Button>
      </Link>
    );
  }

  return (
    <Button
      className={classnames(styles.root, className)}
      iconClassName={styles.icon}
      contentClassName={styles.content}
      startIcon="arrow-narrow-left"
      onClick={() => {
        router.back();
        if (onClick) {
          onClick();
        }
      }}
      dataTestId={dataTestId}
    >
      {t(text)}
    </Button>
  );
};

export default BackButton;

import React from 'react';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';

import { MUTATION_KEYS, QUERY_KEYS } from 'constants/query-keys';

export function useOrderMutationsObserver() {
  const [isInitialRender, setInitialRender] = React.useState(true);

  const isOrderProductMutating = useIsMutating({
    mutationKey: [MUTATION_KEYS.ORDER_PRODUCT],
    exact: true
  });

  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (isOrderProductMutating === 0) {
      if (isInitialRender) {
        setInitialRender(false);
      } else {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACTIVE_ORDER] });
      }
    }
  }, [
    isOrderProductMutating,
    isInitialRender,
    queryClient
  ]);
}

import { FEATURES } from 'constants/features';
import { getDesktopAccountNavigationItems, getMobileAccountNavigationItems } from 'constants/navigation';
import { useUser } from 'hooks/data/useUser';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import type { IMenuItem, IMenuItemLink } from 'types';

interface Params {
  isDesktop?: boolean,
  logoutClassName?: string
}

export function useAccountMenu(params: Params) {

  const {
    isDesktop,
    logoutClassName,
  } = params || {};

  // Hooks

  const { isEnabled: isLoyaltyEnabled } = useFeatureFlag(FEATURES.LOYALTY_ENABLED);
  const { isEnabled: shouldShowLoyaltyNewLabel } = useFeatureFlag(FEATURES.LOYALTY_SHOW_NEW_LABEL);
  const { isEnabled: areBenefitsEnabled } = useFeatureFlag(FEATURES.SHOW_CUSTOMER_BENEFITS);

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  // Items

  const options = {
    logoutClassName,
    //
    canViewBenefitsPage: areBenefitsEnabled,
    canViewLoyaltyPage: isLoyaltyEnabled && !!user?.hasLoyaltyUserConditions,
    shouldShowLoyaltyNewLabel,
    shouldShowVouchersNewLabel: false,
    shouldShowBenefitsNewLabel: true
  };

  const navigationItems = (
    isDesktop
      ? getDesktopAccountNavigationItems(options)
      : getMobileAccountNavigationItems(options)
  );

  const { spotlightedItems, items } = navigationItems.reduce((acc, item) => {
    if (item === 'separator') {
      acc.items.push(item);
      return acc;
    }

    const {
      spotlighted,
      ...rest
    } = item || {};

    if (spotlighted) {
      acc.spotlightedItems.push({ ...rest });
    } else {
      acc.items.push({ ...rest });
    }

    return acc;
  }, {
    spotlightedItems: [] as IMenuItemLink[],
    items: [] as IMenuItem[]
  });

  // Return

  return {
    items,
    spotlightedItems
  };
}

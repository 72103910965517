import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-cool-inview';
import classnames from 'classnames';

import { ProductThumbnails, ProductThumbnailsSkeleton } from 'components/product';
import { Button, Icon, Link } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { searchAndSortSummaryProducts } from 'helpers/OrderHelpers';
import { buildURLWithQueryString } from 'helpers/UrlHelpers';
import { useOrder } from 'hooks/data/useOrder';
import { useMediaQuery } from 'hooks/useMediaQuery';

import OrderDeliveryBadge from '../OrderDeliveryBadge/OrderDeliveryBadge';
import OrderInfoDate from '../OrderInfoDate/OrderInfoDate';
import OrderInfoDelivery from '../OrderInfoDelivery/OrderInfoDelivery';
import OrderInfoPayment from '../OrderInfoPayment/OrderInfoPayment';
import OrderStatusBadge from '../OrderStatusBadge/OrderStatusBadge';

import type { DataTestId, IOrder } from 'types';

import styles from './OrderItem.module.scss';

interface Props extends DataTestId {
  className?: string,
  //
  order?: IOrder,
  urlParams?: any, // TODO: fix type
  //
  inline?: boolean,
  canViewDetails?: boolean,
  //
  withHeader?: boolean,
  withOrderPlacedDate?: boolean,
  withOrderDeliveredDate?: boolean,
  withBorder?: boolean,
  withProductThumbnails?: boolean
}

const OrderItem = (props: Props) => {

  const {
    className,
    //
    order,
    urlParams = {},
    //
    inline,
    canViewDetails = true,
    //
    withHeader = true,
    withOrderPlacedDate = true,
    withOrderDeliveredDate = true,
    withBorder,
    withProductThumbnails,
    //
    dataTestId
  } = props;

  // Hooks

  const { t } = useTranslation();
  const router = useRouter();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const [isInView, setIsInView] = React.useState(false);

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      setIsInView(true);
      unobserve();
    }
  });

  // Props

  const { tokenValue, number } = order || {};

  const asUrl = `${APP_ROUTES.ORDER_HISTORY}/${tokenValue}`;
  const url = buildURLWithQueryString(asUrl, urlParams);

  // Products

  const {
    isLoading,
    data: orderDetails,
  } = useOrder(tokenValue, {
    enabled: withProductThumbnails && isInView
  });

  const { resultsCount, products } = searchAndSortSummaryProducts(orderDetails, urlParams?.searchQuery);

  const thumbnails = resultsCount === 0
    ? orderDetails?.summaryItems
    : products;

  const maxCount = (resultsCount === 0 || resultsCount > 6)
    ? 6
    : resultsCount;

  // Handlers

  const handleViewDetails = () => {
    router.push(url, asUrl);
  };

  // Classes

  const classes = classnames(
    styles.root,
    { [styles.inline]: inline },
    { [styles.bordered]: withBorder },
    className
  );

  return (
    <div
      ref={withProductThumbnails ? observe : null}
      className={classes}
      data-testid={`${dataTestId}.card`}
    >

      {/* Header */}
      {
        withHeader && (
          <div className={styles.header}>

            {/* Title */}
            <div className={styles.title}>

              {/* Name */}
              <div
                className={styles.name}
                data-testid={`${dataTestId}.name`}
              >
                {`${t('account:ORDER_HISTORY.ORDER')} ${number}`}
              </div>

              {/* State Badge */}
              <OrderStatusBadge
                className={classnames(styles.badge, styles.stateBadge)}
                order={order}
                dataTestId={`${dataTestId}.badge.state`}
              />

              {/* Delivery Badge */}
              <OrderDeliveryBadge
                className={classnames(styles.badge, styles.deliveryBadge)}
                order={order}
              />
            </div>

            {/* Actions */}
            {
              !isBreakpoint && canViewDetails && (
                <div className={styles.actions}>
                  <Link
                    href={url}
                    as={asUrl}
                    passHref
                    prefetch={false}
                  >
                    <Button
                      tag="a"
                      className={styles.viewDetailsBtn}
                      variant="secondary"
                      size="small"
                      dataTestId={`${dataTestId}.view-btn`}
                    >
                      <span className={styles.viewDetailsBtnText}>{t('account:ORDER_HISTORY.ORDER_DETAILS')}</span>
                    </Button>
                  </Link>
                </div>
              )
            }
          </div>
        )
      }

      {/* Summary */}
      <div className={styles.summary}>
        <div className={styles.details}>

          {/* Dates */}
          <div className={styles.dates}>

            {/* Placed */}
            {
              withOrderPlacedDate && (
                <OrderInfoDate
                  className={styles.infoBlock}
                  iconClassName={styles.infoBlockIcon}
                  contentClassName={styles.infoBlockContent}
                  labelClassName={styles.infoBlockLabel}
                  valueClassName={styles.infoBlockValue}
                  order={order}
                  withIcon={!isBreakpoint}
                  size="medium"
                />
              )
            }

            {/* Delivery */}
            {
              withOrderDeliveredDate && (
                <OrderInfoDelivery
                  className={styles.infoBlock}
                  iconClassName={styles.infoBlockIcon}
                  contentClassName={styles.infoBlockContent}
                  labelClassName={styles.infoBlockLabel}
                  valueClassName={styles.infoBlockValue}
                  subValueClassName={styles.infoBlockSubValue}
                  withIcon={!isBreakpoint}
                  size="medium"
                  order={order}
                />
              )
            }
          </div>

          {/* Total */}
          <div className={styles.total}>
            <OrderInfoPayment
              label="LABELS.TOTAL"
              className={styles.infoBlock}
              wrapperClassName={styles.infoBlockWrapper}
              innerClassName={styles.infoBlockInner}
              iconClassName={styles.infoBlockIcon}
              contentClassName={styles.infoBlockContent}
              labelClassName={styles.infoBlockLabel}
              valueClassName={styles.infoBlockValue}
              order={order}
              withAmount
              size="medium"
            />
          </div>
        </div>
      </div>

      {/* Product Thumbnails */}
      {
        withProductThumbnails && (
          <div className={styles.products}>
            {
              isLoading
                ? (
                  <ProductThumbnailsSkeleton
                    desktopMaxCount={6}
                    responsiveMaxCount={6}
                  />
                )
                : (
                  <ProductThumbnails
                    products={thumbnails}
                    desktopMaxCount={maxCount + 1}
                    responsiveMaxCount={maxCount + 1}
                    onExtraClick={handleViewDetails}
                  />
                )
            }
          </div>
        )
      }

      {/* Responsive Actions */}
      {
        isBreakpoint && canViewDetails && withHeader && (
          <div className={styles.actions}>
            <Link
              href={url}
              as={asUrl}
              passHref
              prefetch={false}
            >
              <Button
                tag="a"
                className={styles.viewDetailsBtn}
                variant="secondary"
                size="small"
                dataTestId={`${dataTestId}.view-btn`}
              >
                <span className={styles.viewDetailsBtnText}>{t('account:ORDER_HISTORY.ORDER_DETAILS')}</span>
              </Button>
            </Link>
          </div>
        )
      }
    </div>
  );
};

export default OrderItem;

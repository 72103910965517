/* eslint-disable max-len */
import getConfig from 'next/config';

const { serverRuntimeConfig = {}, publicRuntimeConfig = {} } = getConfig() || {};

/* AUTH */
export const AUTH_REDIRECT_URL = `${publicRuntimeConfig.AUTH_URL}/user/authorize?response_type=code&client_id=${publicRuntimeConfig.AUTH_CLIENT_ID}&scope=${publicRuntimeConfig.AUTH_SCOPES}`;
export const REFRESH_TOKEN_ENDPOINT = '/refresh?refresh_token=';

/* Config */
export const CONFIG = {

  // URLs

  BASE_URL: 'https://www.freshful.ro',
  BASE_HOST: 'www.freshful.ro',
  BASE_HOST_SHORT: 'freshful.ro',

  // API URLs

  API_URL: serverRuntimeConfig.API_URL || publicRuntimeConfig.API_URL,
  CMS_API_URL: serverRuntimeConfig.CMS_API_URL || publicRuntimeConfig.CMS_API_URL,
  NEXT_API_URL: '/api/web',

  // External URLs

  EMAG_GENIUS_URL: 'https://www.emag.ro/genius?utm_source=brand_website_freshful&utm_medium=cpc_freshful&utm_campaign=freshful___banner_carousel_hp___unpaid_freshful&utm_content=freshful___banner_carousel_hp___unpaid_freshful&extcmpid=518',
  DEFAULT_DYNAMIC_LINK: 'https://freshful.page.link/homepage',
  EMAG_GENIUS_SCRIPT: publicRuntimeConfig.EMAG_GENIUS_SCRIPT,

  // Misc

  PREFETCH_LINKS: publicRuntimeConfig.CONFIG_PREFETCH_LINKS,
  DISABLE_REFETCH_ON_FOCUS: publicRuntimeConfig.DISABLE_REFETCH_ON_FOCUS,

  // App Version

  APP_VERSION: publicRuntimeConfig.APP_VERSION,

  // Google

  GOOGLE: {
    API_KEY: publicRuntimeConfig.GOOGLE_API_KEY,
    GTM_ID: publicRuntimeConfig.APP_GTM_ID,
    GTM_EVENTS_ENABLED: publicRuntimeConfig.GA_ENABLED,
    OPT_ID: publicRuntimeConfig.APP_OPT_ID,
    DEFAULT_COORDINATES: { lat: 44.435496, lng: 26.102567 },
    RECAPTCHA: {
      SITE_KEY: publicRuntimeConfig.GOOGLE_RECAPTCHA_SITE_KEY
    }
  },

  // Mobile Apps

  MOBILE_APPS: {
    ANDROID_LINK: 'https://play.google.com/store/apps/details?id=ro.freshful.app',
    IOS_LINK: 'https://apps.apple.com/ro/app/freshful-by-emag/id1590338608'
  },

  // Contentful

  CONTENTFUL: {
    SPACE_ID: serverRuntimeConfig.CONTENTFUL_SPACE_ID,
    ACCESS_TOKEN: serverRuntimeConfig.CONTENTFUL_ACCESS_TOKEN,
    PREVIEW_ACCESS_TOKEN: serverRuntimeConfig.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    PREVIEW_SECRET: serverRuntimeConfig.CONTENTFUL_PREVIEW_SECRET,
    GRAPHQL_API_URL: `https://graphql.contentful.com/content/v1/spaces/${serverRuntimeConfig.CONTENTFUL_SPACE_ID}/environments/master`,
    API_URL: `https://cdn.contentful.com/spaces/${serverRuntimeConfig.CONTENTFUL_SPACE_ID}/environments/master`,
  },

  // Strapi

  STRAPI: {
    PREVIEW_SECRET: serverRuntimeConfig.STRAPI_PREVIEW_SECRET,
    PREVIEW_ACCESS_TOKEN: serverRuntimeConfig.STRAPI_PREVIEW_ACCESS_TOKEN,
  },

  // Salesforce

  SALESFORCE: {
    EINSTEIN: {
      IS_ENABLED: publicRuntimeConfig.SALESFORCE_EINSTEIN_ENABLED,
      ORG_ID: '510004847',
    },
    CHAT: {
      IS_ENABLED: publicRuntimeConfig.SALESFORCE_CHAT_ENABLED,
      IS_PROD: publicRuntimeConfig.SALESFORCE_CHAT_PROD,
      PROD: {
        baseCoreURL: 'https://freshful.my.salesforce.com',
        communityEndpointURL: 'https://freshful.my.salesforce-sites.com/Yummy',
        orgId: '00D09000007kXMs',
        eswConfigDevName: 'Chat_Yummy_Client',
        settings: {
          deploymentId: '57209000000gljC',
          buttonId: '57309000000gmma',
          baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-cehfhs.salesforceliveagent.com/content',
          baseLiveAgentURL: 'https://d.la1-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I09000000gdzaEAA_17b71f4c6a3',
          isOfflineSupportEnabled: false
        }
      },
      DEV: {
        baseCoreURL: 'https://freshful--dev.my.salesforce.com',
        communityEndpointURL: 'https://freshful--dev.sandbox.my.salesforce-sites.com/Yummy',
        orgId: '00D1j0000000Mf6',
        eswConfigDevName: 'Yummy_Chat_Clients_Service',
        settings: {
          deploymentId: '5721j00000001Dv',
          buttonId: '5731j00000000sY',
          baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/content',
          baseLiveAgentURL: 'https://d.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1j0000008OwMEAU_179c1456f80',
          isOfflineSupportEnabled: false
        }
      }
    }
  },

  // Pagination

  PAGINATION: {
    DEFAULT_SIZE: 60,
    ORDER_HISTORY: 10,
    RECIPES: 24,
    LOYALTY_HISTORY: 30,
    HOMEPAGE: 4
  },

  // Timeouts

  DURATIONS: {
    DEFAULT_QUERY_STALE_TIME: 1000,
    INITIAL_ADDRESS_CHECK_TIMEOUT: 1500,
    USER_ACTIONS_DEBOUNCE: 300,
    DEFAULT_REQUEST_TIMEOUT: publicRuntimeConfig.DEFAULT_REQUEST_TIMEOUT || 15000,
    PRODUCT_DELETE_TOAST_AUTOCLOSE: 3500,
    PRODUCT_FAVORITES_TOAST_AUTOCLOSE: 3000,
    RECIPE_ADD_INGREDIENTS_TOAST_AUTOCLOSE: 3000,
    BANNER_CAROUSEL_AUTOPLAY: 6000,
    BANNER_CAROUSEL_AUTOPLAY_RESPONSIVE: 6000,
    TOASTS: {
      DEFAULT: 2000,
      LONG: 3500
    }
  },

  // Seasonal

  SEASONAL: {
    CHRISTMAS_ENABLED: false,
    SNOWFALL_ENABLED: false,
    EASTER_ENABLED: false
  },

  // Features

  FEATURES: {
    CMS_CHECKOUT_PAGE_INFO: true
  },

  WEB_VITALS_REPORTING: publicRuntimeConfig.WEB_VITALS_MONITORING
} as const;

import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { useMediaQuery } from 'hooks/useMediaQuery';

import Button from '../Button/Button';

import Icon from '../Icon/Icon';

import type { Props as IconProps } from '../Icon/Icon';
import type { Breakpoint, Size } from 'types/constants';
import type { DataTestId } from 'types';
import { Breakpoints, Sizes } from 'types';

import styles from './ModalHeader.module.scss';

export interface BreakpointSpecificProps {
  sticky?: boolean,
  stuck?: boolean,
  //
  size?: Size,
  closeIconProps?: IconProps,
  //
  withPadding?: boolean,
  withBorder?: boolean,
  withBackButton?: boolean,
  withCloseButton?: boolean
}

export interface Props extends BreakpointSpecificProps, DataTestId {
  className?: string,
  titleClassName?: string,
  closeButtonClassName?: string,
  //
  title?: string | React.ReactElement,
  //
  breakpoint?: Breakpoint,
  desktopProps?: BreakpointSpecificProps,
  responsiveProps?: BreakpointSpecificProps,
  content?: string | React.ReactElement,
  //
  onBack?: () => void,
  onClose?: () => void
}

const ModalHeader = (props: Props) => {

  const {
    //
    className,
    titleClassName,
    closeButtonClassName,
    //
    title,
    //
    size = Sizes.MEDIUM,
    //
    breakpoint = Breakpoints.MD,
    desktopProps = {},
    responsiveProps = {},
    //
    sticky,
    stuck,
    //
    withPadding = true,
    withBorder,
    withBackButton,
    withCloseButton = true,
    //
    content,
    //
    onBack = () => {},
    dataTestId,
    onClose = () => {}
  } = props;

  // Hooks

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles[`breakpoint_${breakpoint}`]})`);

  // Props

  const {
    sticky: stickyAtBreakpoint = sticky,
    stuck: stuckAtBreakpoint = stuck,
    size: sizeAtBreakpoint = size,
    closeIconProps: closeIconPropsAtBreakpoint,
    //
    withPadding: withPaddingAtBreakpoint = withPadding,
    withBorder: withBorderAtBreakpoint = withBorder,
    withBackButton: withBackButtonAtBreakpoint = withBackButton,
    withCloseButton: withCloseButtonAtBreakpoint = withCloseButton,
  } = (isBreakpoint ? responsiveProps : desktopProps) || {};

  // Handlers

  const handleClick = () => {
    if (!withBackButtonAtBreakpoint) return;
    onBack();
  };

  // Title

  const Title = (
    title
      ? (
        <div className={classnames(styles.title, titleClassName)}>
          {typeof title === 'string' ? t(title) : title}
        </div>
      ) : (
        <div />
      )
  );

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.hasTitle]: !!title },
        { [styles.desktop]: !isBreakpoint },
        { [styles.responsive]: isBreakpoint },
        { [styles.sticky]: stickyAtBreakpoint },
        { [styles.stuck]: stuckAtBreakpoint },
        { [styles[sizeAtBreakpoint]]: sizeAtBreakpoint },
        { [styles.bordered]: withBorderAtBreakpoint },
        { [styles.withPadding]: withPaddingAtBreakpoint },
        className
      )}
    >

      {/* Title */}
      {
        withBackButtonAtBreakpoint ? (
          // With Back Icon
          typeof title === 'string'
            ? (
              <button className={classnames(styles.titleWrapper, styles.btn)} type="button" onClick={handleClick}>
                <div className={styles.icon}>
                  <Icon
                    name="arrow-narrow-left"
                    size={isBreakpoint ? 20 : 24}
                    strokeWidth={isBreakpoint ? 2.5 : 2}
                  />
                </div>
                {Title}
              </button>
            ) : (
              <div className={styles.titleWrapper}>
                <button type="button" className={styles.icon} onClick={handleClick}>
                  <Icon
                    name="arrow-narrow-left"
                    size={24}
                    strokeWidth={2}
                  />
                </button>
                {Title}
              </div>
            )
        ) : (
        // Without Back Icon
          Title
        )
      }

      {/* Content */}
      {content}

      {/* Close Button */}
      {
        withCloseButtonAtBreakpoint && (
          <Button
            className={classnames(styles.closeButton, closeButtonClassName)}
            size="small"
            icon={(
              <Icon
                className={styles.closeIcon}
                name="x"
                size={isBreakpoint ? 20 : 24}
                strokeWidth={isBreakpoint ? 2.5 : 2}
                {...(closeIconPropsAtBreakpoint || {})}
              />
            )}
            data-testid={dataTestId ? `${dataTestId}.modal-close-btn` : 'modal-close-btn'}
            onClick={onClose}
          />
        )
      }

    </div>
  );
};

export default ModalHeader;

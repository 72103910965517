import {
  clearBodyLocks,
  lock,
  unlock,
} from 'tua-body-scroll-lock';

import { ScrollLock } from 'common/ScrollLock';

// Constants
export const SCROLL_LOCK_IGNORE_CLASS = 'scroll-lock-ignore';

// Types
interface DisableBodyScrollOptions {
  reserveScrollBarGap?: boolean,
  allowTouchMove?: (el: HTMLElement) => boolean
}

type ScrollBehavior = 'auto' | 'smooth';

interface ScrollToElementOptions {
  behavior?: ScrollBehavior,
  offset?: number,
  withAnnouncementOffset?: boolean,
  withHeaderOffset?: boolean
}

// Body Scroll

export function disableBodyScroll(
  targetElement: HTMLElement,
  overrideOptions: DisableBodyScrollOptions = {}
): void {
  const options: DisableBodyScrollOptions = {
    reserveScrollBarGap: false,
    ...overrideOptions,
  };

  options.allowTouchMove = (el: HTMLElement): boolean => {
    while (el && el !== document.body) {
      if (el.classList.contains(SCROLL_LOCK_IGNORE_CLASS)) {
        return true;
      }
      el = el.parentElement as HTMLElement;
    }
    return false;
  };

  ScrollLock.disableBodyScroll(targetElement, options);

  if (!options.reserveScrollBarGap) {
    document.body.style.setProperty('overflow', 'hidden');
  }
}

export function enableBodyScroll(targetElement: HTMLElement): void {
  ScrollLock.enableBodyScroll(targetElement);
  document.body.style.overflow = null;
}

export function clearAllBodyScrollLocks(): void {
  ScrollLock.clearAllBodyScrollLocks();
  document.body.style.overflow = null;
}

export function getChildrenByClassName(
  parent: HTMLElement | null,
  className: string,
  includeParent = true
): HTMLElement[] {
  const elements: HTMLElement[] = [];

  if (parent) {
    const children = parent.querySelectorAll<HTMLElement>(className);
    elements.push(...Array.from(children));
  }

  return includeParent ? [...elements, parent] : elements;
}

export function disableIosBodyScroll(targetElement: HTMLElement): void {
  lock(targetElement);
}

export function enableIosBodyScroll(targetElement: HTMLElement): void {
  unlock(targetElement);
}

export function clearAllIosBodyScrollLocks(): void {
  clearBodyLocks();
}

// Others

export function scrollParentToChild(parent: HTMLElement, child: HTMLElement | null): void {
  if (!child) return;

  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();

  const isChildFullyVisible = childRect.top >= parentRect.top
    && childRect.bottom <= parentRect.bottom
    && childRect.left >= parentRect.left
    && childRect.right <= parentRect.right;

  if (isChildFullyVisible) return;

  parent.scroll({
    left: childRect.left - parent.clientWidth / 2 + child.clientWidth / 2,
    top: childRect.top - parent.clientHeight / 2 + child.clientHeight / 2,
    behavior: 'smooth',
  });
}

export function isElementInViewport(
  el: HTMLElement,
  parent: Window = window,
  partiallyVisible = false
): boolean {
  try {
    const {
      top, left, bottom, right
    } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = parent;

    return partiallyVisible
      ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight))
          && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  } catch (e) {
    console.error('Failed to check if element is in viewport');
    return false;
  }
}

export function scrollToElementTop(element: HTMLElement, options: ScrollToElementOptions = {}): void {
  try {
    const {
      behavior = 'smooth',
      offset: initialOffset = -50,
      withAnnouncementOffset = true,
      withHeaderOffset = true,
    } = options;

    const { body, documentElement } = document;
    const scrollTop = window.scrollY || body.scrollTop || documentElement.scrollTop;
    const elementTop = element.getBoundingClientRect().top;

    const headerOffset = withHeaderOffset ? -getElementHeightById('header') : 0;
    const announcementOffset = withAnnouncementOffset ? -getElementHeightById('announcement-banner') : 0;
    const offset = headerOffset + announcementOffset + initialOffset;

    window.scrollTo({ top: scrollTop + elementTop + offset, behavior });
  } catch (err) {
    console.error('Failed to scroll to element', err);
  }
}

function getElementHeightById(id: string): number {
  const el = document.getElementById(id);
  return el?.clientHeight ?? 0;
}

import dynamic from 'next/dynamic';

export { default as AccountBenefits } from './AccountBenefits/AccountBenefits';
export { default as AccountBenefitsDynamicContent } from './AccountBenefitsDynamicContent/AccountBenefitsDynamicContent';
export { default as AccountBenefitsList } from './AccountBenefitsList/AccountBenefitsList';
export { default as AccountMenu } from './AccountMenu/AccountMenu';
export { default as AuthRedirect } from './AuthRedirect/AuthRedirect';
export { default as ExternalAuthRedirect } from './AuthRedirect/ExternalAuthRedirect';
export { default as LogoutRedirect } from './AuthRedirect/LogoutRedirect';

export const AccountBenefitsModal = dynamic(() => import('./AccountBenefitsModal/AccountBenefitsModal'));
export const AccountBenefitsModalManager = dynamic(() => import('./AccountBenefitsModalManager/AccountBenefitsModalManager'));
export const PhoneVerificationManager = dynamic(() => import('./PhoneVerificationManager/PhoneVerificationManager'));
export const PhoneVerificationModal = dynamic(() => import('./PhoneVerificationModal/PhoneVerificationModal'));

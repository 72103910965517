import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

import { useResize } from 'hooks/useResize';

import Icon from '../Icon/Icon';

import type { DataTestId } from 'types';

import styles from './Accordion.module.scss';

export interface Controller {
  expand: () => void,
  collapse: () => void
}

export interface Props extends DataTestId {
  className?: string,
  headerClassName?: string,
  headerInnerClassName?: string,
  titleClassName?: string,
  iconWrapperClassName?: string,
  iconClassName?: string,
  iconExpandedClassName?: string,
  iconExpandableClassName?: string,
  contentClassName?: string,
  contentInnerClassName?: string,
  //
  title?: string | React.ReactNode,
  //
  icon?: string,
  unexpandableIcon?: string,
  iconSize?: number,
  iconStrokeWidth?: number,
  //
  expandable?: boolean,
  expanded?: boolean,
  initiallyExpanded?: boolean,
  expandOnIconOnly?: boolean,
  withAnimation?: boolean,
  //
  onInit?: (controller: Controller) => void,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  onExpand?: () => void,
  onCollapse?: () => void,
  //
  children?: React.ReactNode,
  headerChildren?: React.ReactNode
}

const Accordion = (props: Props) => {

  const {
    //
    className,
    headerClassName,
    headerInnerClassName,
    titleClassName,
    iconWrapperClassName,
    iconClassName,
    iconExpandedClassName,
    iconExpandableClassName,
    contentClassName,
    contentInnerClassName,
    //
    title,
    //
    icon = 'chevron-down',
    unexpandableIcon = 'chevron-right',
    iconSize = 20,
    iconStrokeWidth = 1,
    //
    expandable = true,
    expanded,
    initiallyExpanded = false,
    expandOnIconOnly,
    withAnimation,
    //
    onInit,
    onClick,
    onExpand = () => {},
    onCollapse = () => {},
    //
    children,
    headerChildren,
    //
    dataTestId
  } = props;

  // Hooks

  const [isExpanded, setExpanded] = React.useState<boolean | null>(null);

  // Refs

  const controllerRef = useRef<Controller>({
    expand: () => setExpanded(true),
    collapse: () => setExpanded(false)
  });

  // Effects

  React.useEffect(() => {
    if (onInit) {
      onInit(controllerRef.current);
    }
  }, [controllerRef?.current]);

  React.useEffect(() => {
    if (isExpanded === null) {
      setExpanded(initiallyExpanded);
    }
  }, [isExpanded, initiallyExpanded]);

  React.useEffect(() => {
    if (expanded !== undefined) {
      setExpanded(expanded);
    }
  }, [expanded]);

  // Handlers

  const handleClick = (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e as React.MouseEvent<HTMLButtonElement>);
    }

    if (expandable) {
      setExpanded((prev) => !prev);
      if (isExpanded) {
        onCollapse();
      } else {
        onExpand();
      }
    }
  };

  // Tags

  const HeaderTag = expandOnIconOnly ? 'div' : 'button';
  const ChevronTag = expandOnIconOnly ? 'button' : 'div';

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Header */}
      <HeaderTag
        className={classnames(
          styles.header,
          { [styles.animated]: withAnimation },
          headerClassName
        )}
        {
          ...expandOnIconOnly
            ? {}
            : {
              onClick: handleClick,
              'data-testid': dataTestId
            }
        }
      >
        <div className={classnames(styles.inner, headerInnerClassName)}>

          {/* Title */}
          {
            title && (
              <div className={classnames(styles.title, titleClassName)}>
                {title}
              </div>
            )
          }

          {/* Header Children */}
          {headerChildren}

        </div>

        {/* Chevron Icon */}
        <ChevronTag
          className={classnames(styles.iconWrapper, iconWrapperClassName)}
          type="button"
          {
            ...expandOnIconOnly
              ? {
                onClick: handleClick,
                'data-testid': dataTestId
              }
              : {}
          }
        >
          <Icon
            className={classnames(
              styles.icon,
              { [styles.expanded]: isExpanded },
              {
                ...iconExpandedClassName
                  ? { [iconExpandedClassName]: isExpanded }
                  : null
              },
              {
                ...iconExpandableClassName
                  ? { [iconExpandableClassName]: expandable }
                  : {}
              },
              iconClassName,
            )}
            size={iconSize}
            strokeWidth={iconStrokeWidth}
            name={expandable ? icon : unexpandableIcon}
          />
        </ChevronTag>

      </HeaderTag>

      {/* Content */}
      {
        withAnimation
          ? (
            <AccordionContent
              expanded={!!isExpanded}
              className={contentClassName}
              innerClassName={contentInnerClassName}
            >
              {children}
            </AccordionContent>
          ) : isExpanded && children
      }
    </div>
  );
};

// Components

interface AccordionContentProps {
  className?: string,
  innerClassName?: string,
  expanded?: boolean,
  children?: React.ReactNode
}

const AccordionContent = (props: AccordionContentProps) => {
  const {
    className,
    innerClassName,
    expanded,
    children,
  } = props;

  // Refs

  const transitionRef = React.useRef(null);

  // Resize

  const { ref: resizeRef, height } = useResize({ handleWidth: false });

  // Render

  return (
    <Transition
      nodeRef={transitionRef}
      in={expanded}
      timeout={300}
    >
      {
        (state) => {
          return (
            <div
              ref={transitionRef}
              className={classnames(
                styles.content,
                className,
                { [styles[state]]: state }
              )}
              style={{
                maxHeight: expanded ? height : 0
              }}
            >
              <div
                ref={resizeRef}
                className={classnames(
                  styles.contentInner,
                  innerClassName
                )}
              >
                {children}
              </div>
            </div>
          );
        }
      }
    </Transition>
  );
};

// Export

export default Accordion;

import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import OrderStatusBadge from '../OrderStatusBadge/OrderStatusBadge';

import styles from './OrderDetailsTitle.module.scss';

const OrderDetailsTitle = (props) => {

  const { order } = props;
  const { number } = order;

  const { t } = useTranslation();

  return (
    <div className={styles.root}>

      {/* Name */}
      <div
        className={styles.name}
        data-testid="order-details.title"
      >
        {`${t('account:ORDER_HISTORY.ORDER')} ${number}`}
      </div>

      {/* Badges */}
      <div
        className={styles.badges}
        data-testid="order-details.badges"
      >

        <div className={classnames(styles.badge, styles.stateBadge)}>
          <OrderStatusBadge order={order} />
        </div>

      </div>
    </div>
  );
};

export default OrderDetailsTitle;

import React from 'react';

import { HomepageIntroDesktop, HomepageIntroMobile, HomepageSlots } from 'components/homepage';
import { OrderFeedbackReminderModalManager } from 'components/layout';
import { OnboardingManager } from 'components/onboarding';
import { Snowfall } from 'components/seasonal';
import { Responsive } from 'components/ui';

import type { HomepageSlotType, IHomepageSlot } from 'types';
import { Breakpoints, HOMEPAGE_SLOT_TYPES } from 'types';

import styles from './Homepage.module.scss';

interface Props {
  slots: IHomepageSlot[]
}

const Homepage = (props: Props) => {

  const { slots } = props;

  // Props

  const {
    intro: introSlots,
    main: mainSlots
  } = parseSlots(slots);

  // Render

  return (
    <>

      {/* Intro */}
      <Responsive
        breakpoint={Breakpoints.LG}
        strict
        desktop={(
          <HomepageIntroDesktop
            slots={introSlots}
          />
        )}
        mobile={(
          <HomepageIntroMobile
            slots={introSlots}
          />
        )}
      />

      {/* Main Slots */}
      <div className={styles.slots}>
        <HomepageSlots slots={mainSlots} />
      </div>

      {/* Order Feedback Reminder Modal Manager */}
      <OrderFeedbackReminderModalManager />

      {/* Onboarding Manager */}
      <OnboardingManager />

      {/* Snowfall */}
      <Snowfall />

    </>
  );
};

// Helpers

const {
  BANNERS_CAROUSEL,
  PROMOTIONS_CAROUSEL,
  NAVIGATION
} = HOMEPAGE_SLOT_TYPES;

interface IParsedSlots {
  intro: {
    [BANNERS_CAROUSEL]: IHomepageSlot,
    [PROMOTIONS_CAROUSEL]: IHomepageSlot,
    [NAVIGATION]: IHomepageSlot
  },
  main: IHomepageSlot[]
}

const parseSlots = (slots: IHomepageSlot[]): IParsedSlots => {
  return slots.reduce((acc, slot, index) => {
    const { type } = slot || {};
    const updatedSlot = { ...slot, index };

    if (type === BANNERS_CAROUSEL && !acc.intro[BANNERS_CAROUSEL]) {
      acc.intro[BANNERS_CAROUSEL] = updatedSlot;
    } else if (type === PROMOTIONS_CAROUSEL && !acc.intro[PROMOTIONS_CAROUSEL]) {
      acc.intro[PROMOTIONS_CAROUSEL] = updatedSlot;
    } else if (type === NAVIGATION && !acc.intro[NAVIGATION]) {
      acc.intro[NAVIGATION] = updatedSlot;
    } else {
      acc.main.push(updatedSlot);
    }

    return acc;
  }, {
    intro: {} as Record<HomepageSlotType, IHomepageSlot>,
    main: [] as IHomepageSlot[]
  });
};

export default Homepage;

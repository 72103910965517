import { Image } from 'components/ui';
import { IMAGES } from 'constants/images';
import { APP_ROUTES } from 'constants/routes';

import type { IMenuItemLink } from 'types';

// Main Navigation

interface GetNavigationItemsParams {
  shouldShowGeniusLink?: boolean,
  shouldShowGeniusDiscountIcon?: boolean
}

// --- Desktop

export const getDesktopNavigationItems = (params?: GetNavigationItemsParams): IMenuItemLink[] => {
  const {
    shouldShowGeniusLink,
    shouldShowGeniusDiscountIcon
  } = params || {};

  return [
    {
      href: APP_ROUTES.PROMOTIONS,
      label: 'NAVIGATION.PROMOTIONS',
    },
    {
      href: APP_ROUTES.ACCELERATED_SALE,
      label: 'NAVIGATION.ACCELERATED_SALE',
    },
    {
      href: APP_ROUTES.NEW_PRODUCTS,
      label: 'NAVIGATION.NEW_PRODUCTS',
    },
    {
      href: undefined,
      label: 'NAVIGATION.BLOG.RECIPES.DEFAULT',
      children: [
        {
          label: 'NAVIGATION.BLOG.RECIPES.RETETE_FRESHFUL',
          href: APP_ROUTES.RECIPES_LANDING,
          exact: true
        },
        {
          label: 'NAVIGATION.BLOG.RECIPES.POVESTI_DE_POFTA_BUNA',
          href: APP_ROUTES.LANDING_PAGES_POVESTI_DE_POFTA_BUNA,
        },
        {
          label: 'NAVIGATION.BLOG.RECIPES.CE_MAI_BEM',
          href: APP_ROUTES.LANDING_PAGES_CE_MAI_BEM,
        },
      ]
    },
    {
      href: undefined,
      label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.DEFAULT',
      children: [
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.GOOD_DEEDS', href: APP_ROUTES.BLOG_GOOD_DEEDS, exact: true },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.FRESHFUL_MARKET', href: APP_ROUTES.LANDING_PAGES_FRESHFUL_MARKET },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.DIN_LIVADA', href: APP_ROUTES.BLOG_DIN_LIVADA },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.DIN_GRADINA', href: APP_ROUTES.BLOG_DIN_GRADINA },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.MASLINESCU', href: APP_ROUTES.LANDING_PAGES_MASLINESCU },
      ]
    },
    {
      hidden: !shouldShowGeniusLink,
      href: APP_ROUTES.EMAG_GENIUS,
      label: 'NAVIGATION.GENIUS',
      endIcon: shouldShowGeniusDiscountIcon ? GeniusDiscountIcon : undefined
    }
  ];
};

// --- Mobile

export const getMobileNavigationItems = (params?: GetNavigationItemsParams): IMenuItemLink[] => {
  const {
    shouldShowGeniusLink,
    shouldShowGeniusDiscountIcon
  } = params || {};

  return [
    {
      hidden: !shouldShowGeniusLink,
      href: APP_ROUTES.EMAG_GENIUS,
      label: 'NAVIGATION.GENIUS',
      iconImage: IMAGES.BRANDING.GENIUS_ICON,
      endIcon: shouldShowGeniusDiscountIcon ? GeniusDiscountIcon : undefined
    },
    {
      href: APP_ROUTES.MY_FRESHLIST,
      label: 'NAVIGATION.MY_FRESHLIST',
      icon: 'notes'
    },
    {
      href: APP_ROUTES.PROMOTIONS,
      label: 'NAVIGATION.PROMOTIONS',
      icon: 'discount'
    },
    {
      href: APP_ROUTES.ACCELERATED_SALE,
      label: 'NAVIGATION.ACCELERATED_SALE',
      icon: 'save-me'
    },
    {
      href: APP_ROUTES.NEW_PRODUCTS,
      label: 'NAVIGATION.NEW_PRODUCTS',
      icon: 'shopping-bag'
    },
    // {
    //   label: 'NAVIGATION.BLOG.RECIPES.DEFAULT',
    //   key: 'recipes-menu',
    //   icon: 'chef-hat',
    //   children: [
    //     {
    //       label: 'NAVIGATION.BLOG.RECIPES.RETETE_FRESHFUL',
    //       href: APP_ROUTES.RECIPES_LANDING,
    //       exact: true
    //     },
    //     {
    //       label: 'NAVIGATION.BLOG.RECIPES.POVESTI_DE_POFTA_BUNA',
    //       href: APP_ROUTES.LANDING_PAGES_POVESTI_DE_POFTA_BUNA,
    //     },
    //     {
    //       label: 'NAVIGATION.BLOG.RECIPES.CE_MAI_BEM',
    //       href: APP_ROUTES.LANDING_PAGES_CE_MAI_BEM,
    //     },
    //   ]
    // },
    {
      label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.DEFAULT',
      key: 'inspiratie-freshful-menu',
      icon: 'book',
      children: [
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.GOOD_DEEDS', href: APP_ROUTES.BLOG_GOOD_DEEDS, exact: true },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.FRESHFUL_MARKET', href: APP_ROUTES.LANDING_PAGES_FRESHFUL_MARKET },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.DIN_LIVADA', href: APP_ROUTES.BLOG_DIN_LIVADA },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.DIN_GRADINA', href: APP_ROUTES.BLOG_DIN_GRADINA },
        { label: 'NAVIGATION.BLOG.INSPIRATIE_FRESHFUL.MASLINESCU', href: APP_ROUTES.LANDING_PAGES_MASLINESCU },
      ]
    },
  ];
};

// Account navigation

interface GetAccountNavigationItemsParams {
  logoutClassName?: string,
  //
  canViewBenefitsPage?: boolean,
  canViewLoyaltyPage?: boolean,
  shouldShowLoyaltyNewLabel?: boolean,
  shouldShowBenefitsNewLabel?: boolean,
  shouldShowVouchersNewLabel?: boolean
}

// --- Desktop

const NEW_BADGE = {
  text: 'LABELS.NEW',
  textColor: '#FFFFFF',
  backgroundColor: '#07AFF6',
};

type IAccountMenuItem = 'separator' | IMenuItemLink & {
  spotlighted?: boolean
}

export const getDesktopAccountNavigationItems = (params?: GetAccountNavigationItemsParams): IAccountMenuItem[] => {
  const {
    logoutClassName,
    //
    canViewBenefitsPage,
    canViewLoyaltyPage,
    shouldShowLoyaltyNewLabel,
    shouldShowBenefitsNewLabel,
    shouldShowVouchersNewLabel
  } = params || {};

  return [
    {
      href: APP_ROUTES.EMAG_GENIUS,
      label: 'NAVIGATION.GENIUS',
      iconImage: IMAGES.BRANDING.GENIUS_ICON
    },
    {
      href: APP_ROUTES.ACCOUNT,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.ACCOUNT',
      icon: 'user',
      dataTestId: 'account-menu.account',
      exact: true
    },
    {
      href: APP_ROUTES.ORDER_HISTORY,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.ORDER_HISTORY',
      icon: 'list',
      dataTestId: 'account-menu.orders',
      spotlighted: true
    },
    {
      href: APP_ROUTES.MY_FRESHCLUB_HISTORY,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.MY_FRESHCLUB',
      icon: 'freshclub',
      badge: shouldShowLoyaltyNewLabel
        ? NEW_BADGE
        : null,
      hidden: !canViewLoyaltyPage,
      dataTestId: 'account-menu.freshclub',
      spotlighted: true,
    },
    {
      href: APP_ROUTES.VOUCHERS,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.VOUCHERS',
      icon: 'ticket',
      badge: shouldShowVouchersNewLabel
        ? NEW_BADGE
        : null,
      dataTestId: 'account-menu.vouchers',
      spotlighted: true
    },
    {
      href: APP_ROUTES.BENEFITS,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.BENEFITS',
      icon: 'benefits',
      badge: shouldShowBenefitsNewLabel
        ? NEW_BADGE
        : null,
      hidden: !canViewBenefitsPage,
      dataTestId: 'account-menu.benefits'
    },
    {
      href: APP_ROUTES.MEAL_VOUCHERS,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.MEAL_VOUCHERS',
      icon: 'tools-kitchen',
      dataTestId: 'account-menu.meal-vouchers'
    },
    {
      href: APP_ROUTES.ADDRESSES,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.MY_ADDRESSES',
      icon: 'map-pin',
      dataTestId: 'account-menu.addresses'
    },
    {
      href: APP_ROUTES.BILLING,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.BILLING_INFO',
      icon: 'credit-card',
      dataTestId: 'account-menu.billing'
    },
    'separator',
    {
      href: APP_ROUTES.LOGOUT,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.LOG_OUT',
      icon: 'logout',
      dataTestId: 'account-menu.logout',
      className: logoutClassName
    },
  ];
};

// --- Mobile

export const getMobileAccountNavigationItems = (params?: GetAccountNavigationItemsParams): IAccountMenuItem[] => {
  const {
    logoutClassName,
    //
    canViewBenefitsPage,
    canViewLoyaltyPage,
    shouldShowLoyaltyNewLabel,
    shouldShowBenefitsNewLabel,
    shouldShowVouchersNewLabel
  } = params || {};

  return [
    {
      href: APP_ROUTES.EMAG_GENIUS,
      label: 'NAVIGATION.GENIUS',
      iconImage: IMAGES.BRANDING.GENIUS_ICON
    },
    {
      href: APP_ROUTES.ACCOUNT,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.ACCOUNT',
      icon: 'user',
      exact: true,
      dataTestId: 'account-menu.account',
    },
    {
      href: APP_ROUTES.ORDER_HISTORY,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.ORDER_HISTORY',
      icon: 'list',
      dataTestId: 'account-menu.orders',
      spotlighted: true,
    },
    {
      href: APP_ROUTES.MY_FRESHCLUB_HISTORY,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.MY_FRESHCLUB',
      icon: 'freshclub',
      badge: shouldShowLoyaltyNewLabel
        ? NEW_BADGE
        : null,
      hidden: !canViewLoyaltyPage,
      dataTestId: 'account-menu.freshclub',
      spotlighted: true
    },
    {
      href: APP_ROUTES.VOUCHERS,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.VOUCHERS',
      icon: 'ticket',
      badge: shouldShowVouchersNewLabel
        ? NEW_BADGE
        : null,
      dataTestId: 'account-menu.vouchers',
      spotlighted: true
    },
    {
      href: APP_ROUTES.MEAL_VOUCHERS,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.MEAL_VOUCHERS',
      icon: 'tools-kitchen',
      dataTestId: 'account-menu.meal-vouchers'
    },
    {
      href: APP_ROUTES.BENEFITS,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.BENEFITS',
      icon: 'benefits',
      badge: shouldShowBenefitsNewLabel
        ? NEW_BADGE
        : null,
      hidden: !canViewBenefitsPage,
      dataTestId: 'account-menu.benefits'
    },
    {
      href: APP_ROUTES.ADDRESSES,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.MY_ADDRESSES',
      icon: 'map-pin',
      dataTestId: 'account-menu.addresses'
    },
    {
      href: APP_ROUTES.BILLING,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.BILLING_INFO',
      icon: 'credit-card',
      dataTestId: 'account-menu.billing',
    },
    'separator',
    {
      href: APP_ROUTES.LOGOUT,
      label: 'NAVIGATION.ACCOUNT.LOGGED_IN.LOG_OUT',
      icon: 'logout',
      dataTestId: 'account-menu.logout',
      className: logoutClassName
    },
  ];
};

// Footer Navigation

const FOOTER_STATIC_PREFIX = 'info';

export const FOOTER_NAV_ITEMS = [
  {
    title: 'FOOTER.LINKS.ORDERS_AND_DELIVERY.TITLE',
    children: [
      {
        title: 'FOOTER.LINKS.ORDERS_AND_DELIVERY.FRESHFUL_ACCOUNT',
        href: `/${FOOTER_STATIC_PREFIX}/contul-meu`
      },
      {
        title: 'FOOTER.LINKS.ORDERS_AND_DELIVERY.ORDER_PLACEMENT',
        href: `/${FOOTER_STATIC_PREFIX}/cum-comand`
      },
      {
        title: 'FOOTER.LINKS.ORDERS_AND_DELIVERY.PAYMENT_METHODS',
        href: `/${FOOTER_STATIC_PREFIX}/modalitati-de-plata`
      },
      {
        title: 'FOOTER.LINKS.ORDERS_AND_DELIVERY.DELIVERY',
        href: `/${FOOTER_STATIC_PREFIX}/livrarea`
      }
    ]
  },
  {
    title: 'FOOTER.LINKS.CUSTOMER_SUPPORT.TITLE',
    children: [
      {
        title: 'FOOTER.LINKS.CUSTOMER_SUPPORT.CONTACT',
        href: `/${FOOTER_STATIC_PREFIX}/contact`
      },
      {
        title: 'FOOTER.LINKS.CUSTOMER_SUPPORT.GENERAL_INFO',
        href: `/${FOOTER_STATIC_PREFIX}/informatii-generale`
      },
      {
        title: 'FOOTER.LINKS.CUSTOMER_SUPPORT.FAQ',
        href: `/${FOOTER_STATIC_PREFIX}/faq`
      },
      {
        title: 'FOOTER.LINKS.CUSTOMER_SUPPORT.CAMPAIGN_REGULATIONS',
        href: `/${FOOTER_STATIC_PREFIX}/regulament-campanii`
      },
      {
        title: 'FOOTER.LINKS.CUSTOMER_SUPPORT.ANPC',
        href: 'https://www.anpc.ro/',
        external: true,
      }
    ]
  },
  {
    title: 'FOOTER.LINKS.FRESHFUL.TITLE',
    children: [
      {
        title: 'FOOTER.LINKS.FRESHFUL.SGR_COLLECTION',
        href: APP_ROUTES.LANDING_PAGES_SGR_COLLECTION,
      },
      {
        title: 'FOOTER.LINKS.FRESHFUL.ABOUT_EMAG',
        href: 'https://about.emag.ro/',
        external: true
      },
      {
        title: 'FOOTER.LINKS.FRESHFUL.GENIUS',
        href: APP_ROUTES.GENIUS
      },
      {
        title: 'FOOTER.LINKS.FRESHFUL.CAREERS',
        href: APP_ROUTES.CAREERS
      },
      {
        title: 'FOOTER.LINKS.FRESHFUL.TERMS_AND_CONDITIONS',
        href: `/${FOOTER_STATIC_PREFIX}/termene-si-conditii`
      },
      {
        title: 'FOOTER.LINKS.FRESHFUL.GDPR',
        href: `/${FOOTER_STATIC_PREFIX}/gdpr`
      },
      {
        title: 'FOOTER.LINKS.FRESHFUL.COOKIES',
        href: `/${FOOTER_STATIC_PREFIX}/cookie`
      }
    ]
  }
];

// Misc

const GeniusDiscountIcon = (
  <Image
    src={IMAGES.BRANDING.GENIUS_DISCOUNT}
    quality={100}
    unoptimized
    height={16}
    width={16}
    alt="Genius discount"
  />
);

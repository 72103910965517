import React, { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';

import { AccountMenu } from 'components/account';
import { Scrollbar } from 'components/ui';

import styles from './AccountNavigation.module.scss';

const AccountNavigation = forwardRef<HTMLDivElement, any>((
  props: any,
  ref: React.Ref<HTMLDivElement>
) => {

  // Hooks

  const { t } = useTranslation();

  // Render

  return (
    <div
      className={styles.root}
      data-testid="account-navigation"
    >
      <Scrollbar
        className={styles.scrollbar}
        autoHide={false}
        forceVisible="y"
      >

        {/* Title */}
        <div className={styles.title}>{t('NAVIGATION.ACCOUNT.TITLE')}</div>

        {/* Content */}
        <div
          ref={ref}
          className={styles.content}
        >

          {/* Menu */}
          <AccountMenu
            className={styles.accountMenu}
            menuProps={{
              className: styles.menu,
              itemClassName: styles.item,
              linkClassName: styles.link,
            }}
            //
            isDesktop
          />

        </div>

      </Scrollbar>
    </div>
  );
});

export default AccountNavigation;

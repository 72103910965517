import React from 'react';
import classnames from 'classnames';

import { useModalDispatchHelpers } from 'context/ModalContext';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import * as OrderHelpers from 'helpers/OrderHelpers';
import { useProductCardActions } from 'hooks/useProductCardActions';

import { ProductAddToCart, ProductSimilarProductsButton } from '../../parts';

import type {
  IAnalyticsListProps, IOrder, IOrderProduct, IProduct
} from 'types';

import styles from './ProductSearchCardActions.module.scss';

interface Props {
  className?: string,
  //
  order?: IOrder,
  product: IProduct,
  orderItem?: IOrderProduct,
  listProps?: IAnalyticsListProps,
  bypassQuantityVariants?: boolean,
  //
  withAddToCartText?: boolean,
  //
  onLoading: (isLoading: boolean) => void,
  onQuantityChange?: (data: IOrder) => void
}

const ProductSearchCardActions = (props: Props) => {

  const {
    //
    className,
    //
    order,
    product,
    orderItem,
    listProps = {},
    bypassQuantityVariants = false,
    //
    withAddToCartText,
    //
    onLoading = () => {},
    onQuantityChange: onQuantityChangeSuccess = () => {},
    //
  } = props;

  // Hooks

  const { showModal } = useModalDispatchHelpers();

  // --- Product Card Actions Hook

  const {
    isProductActionLoading,
    isProductActionError,
    //
    handleAdd,
    handleBuyMore,
    handleQuantityChange,
  } = useProductCardActions({
    //
    order,
    product,
    listProps,
    //
    bypassAddressRequirement: true,
    bypassQuantityVariants,
    //
    onQuantityChangeSuccess,
  });

  // Props

  const quantity = React.useMemo(() => OrderHelpers.getOrderItemQuantity(order, product), [order, product]);

  const { isAvailable, slug } = product || {};
  const { isOk } = orderItem || {};

  // Hooks

  // Handlers

  const onSimilarClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    showModal(MODAL_TYPES.SIMILAR_PRODUCTS, { productSlug: slug });
  }, [slug, showModal]);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //  Effects

  React.useEffect(() => {
    onLoading(isProductActionLoading);
  }, [isProductActionLoading]);

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        className
      )}
      onClick={onClick}
    >
      {
        !isAvailable
          ? (
            // Similar Product Button
            <ProductSimilarProductsButton
              onClick={onSimilarClick}
              size="small"
            />
          ) : (
            // Add To Cart Actions
            <ProductAddToCart
              product={product}
              quantity={quantity}
              //
              size="small"
              isCompact
              isVerbose={false}
              //
              isAddToCartDisabled={isProductActionLoading}
              withAddToCartIcon
              withAddToCartText={withAddToCartText}
              withShortenedAddToCartText
              //
              shouldReset={isProductActionError}
              shouldAdjustQuantity={!isOk}
              //
              canAddMultiple={product?.canAddMultiple}
              canAddMultipleType={product?.canAddMultipleType}
              bypassQuantityVariants={bypassQuantityVariants}
              withWideBuyMoreButton
              //
              onAddToCartClick={handleAdd}
              onQuantityChange={handleQuantityChange}
              onBuyMoreClick={handleBuyMore}
            />
          )
      }
    </div>
  );
};

export default React.memo(ProductSearchCardActions);

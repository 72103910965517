import type { CSSProperties } from 'react';
import React from 'react';
import classnames from 'classnames';

import { Icon, Image } from 'components/ui';
import { IMAGES } from 'constants/images';

import type {
  Breakpoint, IProductLabel, IProductLabelItem, Nullable, ProductLabelType, Size,
} from 'types';

import { Breakpoints, ProductLabelTypes, Sizes } from 'types';

import styles from './ProductLabel.module.scss';

export interface Props {
  className?: string,
  label: IProductLabel,
  size?: Size,
  responsiveSize?: Size,
  breakpoint?: Breakpoint,
  rounded?: boolean,
  withLeftOffset?: boolean
}

const ProductLabel = (props: Props) => {
  const {
    className,
    label,
    size = Sizes.MEDIUM,
    responsiveSize = Sizes.SMALL,
    breakpoint = Breakpoints.SM,
    rounded,
    withLeftOffset = false
  } = props;

  // Empty

  if (!label) return null;

  // Props

  const { type, items } = label || {};

  const Component = getLabelComponentByType(type);

  // Empty

  if (!Component || !items || !items?.length) return null;

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles[size]]: size },
        { [styles[`responsive-${responsiveSize}`]]: responsiveSize },
        { [styles[`break-at-${breakpoint}`]]: breakpoint },
        { [styles.rounded]: rounded },
        { [styles.withLeftOffset]: withLeftOffset },
        className
      )}
      style={{
        '--breakpoint': breakpoint
      } as CSSProperties}
    >
      <Component {...label} />
    </div>
  );
};

// Components

interface GenericLabelProps {
  items?: IProductLabelItem[]
}

// --- InfoLabel - v1

const InfoLabel = (props: GenericLabelProps) => {
  const { items = [] } = props;

  return (
    <>
      <Label {...items[0]} className={classnames(styles.infoLabel)} />
    </>
  );
};

// --- Composite - v2

const CompositeLabel = (props: GenericLabelProps) => {
  const { items = [] } = props;
  if (!items || !(items.length > 1)) return null;
  return (
    <>
      <Label {...items[0]} beneath />
      <Label {...items[1]} rounded overlapped />
    </>
  );
};

// --- Simple - v3

const SimpleLabel = (props: GenericLabelProps) => {
  const { items = [] } = props;
  return (
    <>
      <Label {...items[0]} className={styles.simpleLabel} rounded />
    </>
  );
};

// --- Genius Deals

const GeniusDealsLabel = (props: GenericLabelProps) => {
  const { items = [] } = props;
  if (!items || !(items.length > 1)) return null;
  return (
    <div className={styles.geniusDealsContainer}>
      <div className={classnames(styles.geniusDealsLogo, styles.beneath)}>
        <Image
          src={IMAGES.BRANDING.GENIUS_DEALS_LOGO}
          width={73}
          height={19}
          optimize
        />
      </div>
      <Label {...items[1]} rounded overlapped />
    </div>
  );
};

// --- Sponsored

const SponsoredLabel = (props: GenericLabelProps) => {
  const { items = [] } = props;
  const [item] = items;
  const { text } = item || {};
  if (!text) return;
  return (
    <Label
      className={styles.sponsoredLabel}
      text={text}
      circled
    />
  );
};

// --- Label

interface LabelProps extends IProductLabelItem {
  className?: string,
  isInternalIcon?: boolean,
  rounded?: boolean,
  circled?: boolean,
  beneath?: boolean,
  overlapped?: boolean
}

const Label = (props: LabelProps) => {
  const {
    className,
    text = '',
    textColor = '',
    backgroundColor = '',
    icon,
    isInternalIcon,
    rounded,
    circled,
    beneath,
    overlapped
  } = props;

  return (
    <div
      className={classnames(
        styles.label,
        { [styles.rounded]: rounded },
        { [styles.circled]: circled },
        { [styles.withIcon]: !!icon },
        { [styles.beneath]: beneath },
        { [styles.overlapped]: overlapped },
        className,
      )}
      style={{
        ...(textColor ? { color: textColor } : {}),
        ...(backgroundColor ? { backgroundColor } : {})
      }}
    >
      {
        icon && (
          isInternalIcon
            ? (
              <Icon
                className={styles.labelIcon}
                name={icon}
              />
            ) : (
              <img
                className={styles.labelIcon}
                alt={text}
                src={icon}
              />
            )
        )
      }
      <span className={styles.labelText}>
        {text}
      </span>
    </div>
  );
};

// --- Helpers

const getLabelComponentByType = (type: ProductLabelType): Nullable<((props: GenericLabelProps) => React.JSX.Element | null)> => {
  switch (type) {
    case ProductLabelTypes.INFO:
      return InfoLabel;
    case ProductLabelTypes.COMPOSITE:
      return CompositeLabel;
    case ProductLabelTypes.SIMPLE:
      return SimpleLabel;
    case ProductLabelTypes.GENIUS_DEALS:
      return GeniusDealsLabel;
    case ProductLabelTypes.SPONSORED:
      return SponsoredLabel;
    default:
      return null;
  }
};

// Export

export default React.memo(ProductLabel);

import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon/Icon';
import Input from '../Input/Input';

import type { DataTestId } from 'types';

import styles from './SearchBox.module.scss';

// Types

interface Props extends DataTestId {
  className?: string,
  inputWrapperClassName?: string,
  inputClassName?: string,
  searchIconClassName?: string,
  clearIconClassName?: string,
  //
  size?: string,
  variant?: string,
  rounded: boolean,
  //
  delay?: number,
  placeholder?: string,
  //
  onChange?: (query: string) => void,
  onFocus?: () => void,
  onBlur?: () => void
}

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

const VARIANTS = {
  GRAY: 'gray',
  WHITE: 'white',
};

const SearchBox = React.forwardRef((props: Props, ref) => {

  const {
    //
    className,
    inputWrapperClassName,
    inputClassName,
    searchIconClassName,
    clearIconClassName,
    //
    size = SIZES.MEDIUM,
    variant = VARIANTS.GRAY,
    rounded = true,
    //
    delay = 300,
    placeholder,
    //
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    //
    dataTestId
  } = props;

  // Refs

  const inputRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);

  // Handlers

  const handleSearchClear = () => {
    onChange('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  // Render

  return (
    <div className={classnames(styles.root, className)}>
      <Input
        ref={(el) => {
          if (ref) {
            // TODO: remove after Input component is migrated to TS
            // @ts-ignore
            ref.current = el;
          }
          inputRef.current = el;
        }}
        // TODO: remove after Input component is migrated to TS
        // @ts-ignore
        className={
          classnames(
            styles.inputWrapper,
            { [styles[size]]: size },
            inputWrapperClassName
          )
        }
        inputClassName={
          classnames(
            styles.input,
            { [styles[variant]]: variant },
            { [styles.rounded]: rounded },
            inputClassName
          )
        }
        startIconClassName={classnames(styles.searchIcon, searchIconClassName)}
        endIconClassName={classnames(styles.clearIcon, { [styles.visible]: inputRef?.current?.value }, clearIconClassName)}
        //
        type="text"
        placeholder={placeholder}
        delay={delay}
        withoutForm
        //
        startIcon={(
          <Icon name="search" size={16} strokeWidth={2} />
        )}
        endIcon={(
          <Icon name="x" size={16} strokeWidth={2} onClick={handleSearchClear} />
        )}
        //
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          onChange(value);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        //
        dataTestId={dataTestId}
      />
    </div>
  );
});

export default SearchBox;

import React from 'react';
import classnames from 'classnames';

import { Fade } from 'components/animations';
import {
  Button, Icon, Loader,
} from 'components/ui';
import { useModalDispatchHelpers } from 'context/ModalContext';
import { MODAL_TYPES } from 'helpers/LayoutHelpers';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useAnalytics } from 'hooks/useAnalytics';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useProductCardActions } from 'hooks/useProductCardActions';

import { ProductImage, ProductLabel, ProductPrice } from '../../parts';

import ProductDeleteToastBody from '../../ProductDeleteToastBody/ProductDeleteToastBody';
import ProductDeliveryComment from '../../ProductDeliveryComment/ProductDeliveryComment';

import ProductCheckoutCardActions from './ProductCheckoutCardActions';

import { ProductLabelTypes } from 'types';
import type {
  DataTestId, IAnalyticsListProps, IOrder, IOrderProduct
} from 'types';

import styles from './ProductCheckoutCard.module.scss';

export const IMAGE_SIZE = 96;
export const RESPONSIVE_IMAGE_SIZE = 64;

interface Props extends DataTestId {
  className?: string,
  //
  order: IOrder,
  product: IOrderProduct,
  //
  listProps: IAnalyticsListProps,
  //
  setIsLoading: (isLoading: boolean) => void
}

const ProductCheckoutCard = (props: Props) => {
  const {
    className,
    //
    order,
    product,
    //
    listProps = ({} as IAnalyticsListProps),
    //
    dataTestId,
    //
    setIsLoading = () => {}
  } = props;

  const {
    variantCode,
    productName,
    productSlug,
    //
    image,
    badge,
    textTags = [],
    //
    subtotal,
    originalSubtotal,
    unitPriceLabel,
    priceColor,
    priceIcon,
    taxes,
    //
    error = 'Something went wrong',
    //
    isOk,
  } = product || {};

  const {
    currency
  } = order || {};

  // Refs

  const cardRef = React.useRef<HTMLDivElement>(null);

  // Hooks

  const analytics = useAnalytics();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  // Context Hooks

  const { showModal } = useModalDispatchHelpers();

  // Product Card Actions Hook

  const {
    isProductActionLoading,
    isProductActionError,
    //
    handleRemove,
    handleQuantityChange,
  } = useProductCardActions({
    order,
    orderItem: product,
    listProps,
    //
    onEditStart: () => setIsLoading(true),
    onEditSettled: () => setIsLoading(false),
    //
    onRemoveStart: () => setIsLoading(true),
    onRemoveSettled: () => setIsLoading(false),
    onRemoveSuccess: () => {
      ProductHelpers.showDeleteNotification({
        component: ProductDeleteToastBody,
        order,
        product,
        listProps,
        onSuccess: () => analytics.undoDeleteProductFromCheckout()
      });
    }
  });

  // Handlers

  const onReplaceClick = React.useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    showModal(
      MODAL_TYPES.SIMILAR_PRODUCTS,
      { productSlug, productVariantCode: variantCode }
    );
  }, [productSlug, variantCode, showModal]);

  const onDeliveryCommentFocus = () => {
    // Scroll product card into view when delivery comment field is focused
    if (isBreakpoint) {
      cardRef?.current?.scrollIntoView();
    }
  };

  // Props

  // --- Image

  const imageSize = isBreakpoint
    ? RESPONSIVE_IMAGE_SIZE
    : IMAGE_SIZE;

  // --- Labels

  const labels = textTags?.map((tag) => ({ type: ProductLabelTypes.SIMPLE, items: [tag] }));

  // Render

  return (
    <div
      ref={cardRef}
      className={classnames(styles.root, className)}
      data-testid={dataTestId}
    >

      {/* Image */}
      <ProductImage
        alt={productName}
        src={image}
        size={imageSize}
        retina
        unavailable={!isOk}
        rounded
        badge={badge}
        badgeProps={{
          size: 'small',
          withPrefix: false,
        }}
      />

      {/* Content */}
      <div className={styles.content}>

        {/* Remove */}
        <Button
          className={styles.removeBtn}
          variant="empty"
          size="small"
          icon={CloseIcon}
          onClick={handleRemove}
        />

        {/* Name / Unit Price Label */}
        <div className={styles.main}>
          <div className={styles.name}>{productName}</div>
          <div className={styles.detail}>{unitPriceLabel}</div>
        </div>

        {/* Error / Product Delivery Comment */}
        <div className={styles.secondary}>
          {
            !isOk
              ? (
                <>
                  {/* Error */}
                  <div className={styles.errorContainer}>
                    <div className={styles.error}>{error}</div>
                  </div>
                </>
              )
              : (
                <>
                  {/* Delivery Comment */}
                  <ProductDeliveryComment
                    orderItem={product}
                    className={styles.comment}
                    onFocus={onDeliveryCommentFocus}
                  />
                </>
              )
          }

          {/* Label Row */}
          {
            labels && labels?.length > 0 && (
              <div className={classnames(styles.label)}>
                <ProductLabel size="medium" label={labels[0]} rounded />
              </div>
            )
          }
        </div>

        {/* Footer */}
        <div className={styles.footer}>

          {/* Actions */}
          <div className={styles.actionsContainer}>
            <ProductCheckoutCardActions
              orderItem={product}
              //
              shouldReset={isProductActionError}
              //
              onQuantityChange={handleQuantityChange}
              onReplace={onReplaceClick}
            />
          </div>

          {/* Price */}
          <div className={styles.priceContainer}>

            {/* Loader */}
            <Fade
              className={styles.loader}
              isVisible={isProductActionLoading}
              lazyLoad
              enterDuration={400}
              enterDelay={200}
              exitDelay={400}
              exitDuration={400}
            >
              <Loader
                size={18}
                lineWeight={2}
                speed={1.2}
              />
            </Fade>

            {/* Product Price */}
            <Fade
              isVisible={!isProductActionLoading}
              persist
              enterDuration={400}
              enterDelay={1000}
              exitDuration={400}
            >
              <ProductPrice
                className={styles.productPrice}
                originalPriceClassName={styles.originalPrice}
                taxesClassName={styles.taxes}
                align="end"
                size="medium"
                //
                currency={currency}
                price={subtotal}
                originalPrice={originalSubtotal}
                taxes={taxes}
                //
                color={priceColor}
                icon={priceIcon}
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

// Components

const CloseIcon = (
  <Icon
    name="x"
    size={16}
    strokeWidth={2}
  />
);

// Export

export default React.memo(ProductCheckoutCard);

import React from 'react';
import { useTranslation } from 'next-i18next';

import * as Notifications from 'common/Notification';
import {
  Button, Image, Input, Textarea
} from 'components/ui';
import { useChatDispatchHelpers, useChatStore } from 'context/ChatContext';
import { useOrderReportProblemDispatchHelpers } from 'context/OrderReportProblemContext';
import { ORDER_PROBLEM_TYPES } from 'helpers/OrderHelpers';
import { removeRoPrefix } from 'helpers/PhoneNumberHelpers';
import { useConfig } from 'hooks/data/useConfig';
import { useOrderMutation } from 'hooks/data/useOrderMutation';

import { useUser } from 'hooks/data/useUser';

import OrderProblemSuccess from './OrderProblemSuccess';

import styles from './OrderProblemSummary.module.scss';

const OrderProblemSummary = (props) => {
  const {
    payload, problem = {}, order, closeModal
  } = props;

  // Hooks

  const { t } = useTranslation();

  const [message, setMessage] = React.useState();
  const [messageError, setMessageError] = React.useState();

  // Data Hooks

  const { data: config } = useConfig();
  const { data: user } = useUser();

  // Mutation Hooks

  const { reportProblem, isLoading: isMutationLoading } = useOrderMutation();

  // Context Hooks

  const { startChat } = useChatDispatchHelpers();
  const { isAgentAvailable, isLoading: isChatLoading } = useChatStore();
  const { showStep } = useOrderReportProblemDispatchHelpers();

  // Props

  const { type, name } = problem;
  const { orderProblemTypes } = config || {};

  const typeLabel = orderProblemTypes?.find((orderProblemType) => {
    return type === orderProblemType?.type;
  })?.name;

  const isOtherProblemType = type === ORDER_PROBLEM_TYPES.OTHER;

  const messageLabel = isOtherProblemType
    ? t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_MESSAGE')
    : t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_MESSAGE_OPTIONAL');

  const isButtonDisabled = isOtherProblemType
    ? !message || !!messageError
    : false;

  // Handlers

  const handleTextareaChange = (e) => {
    e.preventDefault();
    if (e.target.value?.length > 255) {
      setMessageError(t('VALIDATION_ERRORS.MAX_LENGTH', { count: 255 }));
    } if (isOtherProblemType && e.target.value?.trim()?.length === 0) {
      setMessageError(t('VALIDATION_ERRORS.REQUIRED'));
    } else {
      setMessageError(false);
    }
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    reportProblem({
      tokenValue: order?.tokenValue,
      payload: {
        type,
        typeLabel,
        message,
        ...payload
      },
      onSuccess: () => {
        showStep({
          title: '',
          component: OrderProblemSuccess,
          componentProps: { closeModal },
          modalProps: { className: styles.successModal }
        });
      },
      onError: () => Notifications.showError(t('ERRORS.DEFAULT_TOAST'), { autoClose: 2000, toastId: 'reportProblemError' })
    });
  };

  const onStartChatClick = async () => {
    await startChat({
      name: user?.fullName,
      email: user?.email,
      phone: removeRoPrefix(user?.phoneNumber)
    }, {
      type: problem?.type,
      secondType: payload?.secondType
    });
    closeModal();
  };

  // Helpers

  const getContent = () => {
    switch (type) {
      case ORDER_PROBLEM_TYPES.INCOMPLETE:
        return (
          <IncompleteProblem selection={payload?.orderItems} />
        );
      case ORDER_PROBLEM_TYPES.RETURN:
        return (
          <ReturnProblem selection={payload?.orderItems} />);
      case ORDER_PROBLEM_TYPES.DELIVERY:
        return (
          <DeliveryProblem label={payload?.secondTypeLabel} />
        );
      default:
        return null;
    }
  };

  // Render

  return (
    <div className={styles.root}>

      {/* Type */}
      <div className={styles.section}>
        <div className={styles.label}>{t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_PROBLEM')}</div>
        <Input withoutForm disabled readOnly value={name} />
      </div>

      {/* Subtype */}
      {getContent()}

      {/* Message */}
      <div className={styles.section}>
        <div className={styles.label}>{messageLabel}</div>
        <Textarea
          autoFocus
          withoutForm
          placeholder={type === ORDER_PROBLEM_TYPES.OTHER ? t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_OTHER_PROBLEM_PLACEHOLDER') : ''}
          className={styles.textarea}
          maxRows={10}
          minRows={4}
          onChange={handleTextareaChange}
          value={message}
          error={messageError}
          data-testid="order-problem-modal.summary.message"
        />
      </div>

      {/* Actions */}
      <div className={styles.footer}>
        {
          isAgentAvailable && (
            <Button
              className={styles.chatButton}
              variant="secondary"
              onClick={onStartChatClick}
              loading={isChatLoading}
              disabled={isChatLoading}
            >
              {t('account:ORDER_HISTORY.START_CHAT')}
            </Button>
          )
        }
        <Button
          variant="primary"
          onClick={handleSubmit}
          loading={isMutationLoading}
          disabled={isButtonDisabled || isMutationLoading}
          dataTestId="order-problem-modal.summary.submit-btn"
        >
          {t('account:ORDER_HISTORY.REPORT_ISSUE_SEND')}
        </Button>
      </div>

    </div>
  );
};

const IncompleteProblem = React.memo((props) => {
  const { selection = [] } = props;
  const { data: config } = useConfig();
  const { orderProblemTypes } = config || {};
  const problemType = orderProblemTypes?.find(({ type }) => type === ORDER_PROBLEM_TYPES.INCOMPLETE);
  const { itemSelectionGroups = [] } = problemType || {};

  const groups = itemSelectionGroups.map(({ group, name }) => {
    const products = selection.filter((product) => product.groups.includes(group));
    return { group, name, products };
  }).filter(({ products }) => products?.length > 0);

  return groups.map(({ group, name, products }) => (
    <div className={styles.section} key={group}>
      <div className={styles.label}>{name}</div>
      <div className={styles.list}>
        {products.map((product) => {
          const { productName, image, productSlug } = product;
          const IMAGE_SIZE = 24;
          return (
            <div className={styles.product} key={productSlug}>
              <div className={styles.imageContainer}>
                <Image
                  src={image}
                  width={IMAGE_SIZE}
                  height={IMAGE_SIZE}
                />
              </div>
              <div className={styles.nameContainer}>
                {productName}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ));
});

const ReturnProblem = React.memo((props) => {
  const { selection = [] } = props;
  const { data: config } = useConfig();
  const { orderProblemTypes } = config || {};
  const problemType = orderProblemTypes?.find(({ type }) => type === ORDER_PROBLEM_TYPES.RETURN);
  const { itemSelectionGroups = [] } = problemType || {};
  const groups = itemSelectionGroups.map(({ group, name }) => {
    const products = selection.filter((product) => product.groups.includes(group));
    return { group, name, products };
  }).filter(({ products }) => products?.length > 0);

  return groups.map(({ group, name, products }) => (
    <div className={styles.section} key={group}>
      <div className={styles.label}>{name}</div>
      <div className={styles.list}>
        {products.map((product) => {
          const { productName, image, productSlug } = product;
          const IMAGE_SIZE = 24;
          return (
            <div className={styles.product} key={productSlug}>
              <div className={styles.imageContainer}>
                <Image
                  src={image}
                  height={IMAGE_SIZE}
                  width={IMAGE_SIZE}
                />
              </div>
              <div className={styles.nameContainer}>
                {productName}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ));
});

const DeliveryProblem = (props) => {
  const { t } = useTranslation();
  const { label } = props;
  return (
    <div className={styles.section}>
      <div className={styles.label}>{t('account:ORDER_HISTORY.REPORT_ISSUE_SUMMARY_REASON')}</div>
      <Input withoutForm disabled readOnly value={label} />
    </div>
  );
};

export default OrderProblemSummary;

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import styles from './ModalOverlay.module.scss';

interface Props {
  isOpen: boolean,
  //
  className?: string,
  backgroundClassName?: string,
  //
  position: any,
  isResponsive: boolean,
  isFullScreen: boolean,
  //
  onEntering?: () => void,
  onEntered?: () => void,
  onExiting?: () => void,
  onExited?: () => void,
  //
  children?: React.ReactElement
}

const ModalOverlay = React.forwardRef<HTMLDivElement | null, Props>((
  props: Props,
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  const {
    isOpen,
    //
    backgroundClassName,
    //
    position,
    isResponsive,
    isFullScreen,
    //
    onEntering = () => {},
    onEntered = () => {},
    onExiting = () => {},
    onExited = () => {},
    //
    children,
    ...overlayProps
  } = props;

  const {
    className,
    ...overlayPropsRest
  } = overlayProps;

  // Refs

  const transitionRef = React.useRef(null);

  // Hooks

  const [isEntered, setIsEntered] = React.useState(false);

  // Render

  return (
    <CSSTransition
      in={isOpen}
      nodeRef={transitionRef}
      timeout={{
        enter: 200,
        exit: 200,
      }}
      onEntering={onEntering}
      onEntered={() => {
        setIsEntered(true);
        onEntered();
      }}
      onExiting={onExiting}
      onExited={onExited}
      classNames={{
        enter: styles.entering,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        //
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
    >
      <div
        ref={ref}
        className={classnames(
          styles.root,
          { [styles[position]]: !!position },
          { [styles.responsive]: isResponsive },
          { [styles.fullScreen]: isFullScreen },
          className
        )}
        data-testid="modal-overlay"
        {...overlayPropsRest}
      >
        <div
          ref={transitionRef}
          className={classnames(
            styles.background,
            { [styles.responsive]: isResponsive },
            { [styles.enterDone]: isEntered },
            backgroundClassName
          )}
        />
        {children}
      </div>
    </CSSTransition>
  );
});

export default ModalOverlay;

import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { GroupedProductList } from 'components/product';
import { FEATURES } from 'constants/features';
import { useOrderGifts } from 'hooks/data/useOrderGifts';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import OrderProduct from './OrderProduct';

import type { IOrder, IOrderProduct } from 'types';

import styles from './OrderProductList.module.scss';

interface Props {
  className?: string,
  groupClassName?: string,
  groupHeaderClassName?: string,
  groupTitleClassName?: string,
  itemClassName?: string,
  productClassName?: string,
  productNameClassName?: string,
  productPriceClassName?: string,
  productQuantityClassName?: string,
  titleClassName?: string,
  //
  title?: string,
  order?: IOrder,
  //
  inline?: boolean,
  ungroup?: boolean,
  productDataTestId?: string
}

const OrderProductList = (props: Props) => {
  const {
    //
    className,
    titleClassName,
    groupClassName,
    groupHeaderClassName,
    groupTitleClassName,
    itemClassName,
    productClassName,
    productNameClassName,
    productPriceClassName,
    productQuantityClassName,
    //
    title,
    order,
    //
    inline,
    ungroup,
    productDataTestId
  } = props;

  const {
    currency, summaryItems
  } = order || {};

  // Hooks

  const { t } = useTranslation();

  // Features

  const { isEnabled: shouldGroupProducts } = useFeatureFlag(FEATURES.GROUP_PRODUCTS);

  const withoutGrouping = !shouldGroupProducts || !!ungroup;

  // Data Hooks

  const { data: { categoryTitle } = {} } = useOrderGifts(order?.tokenValue);

  // Parse order items

  const items = React.useMemo(
    () => {
      return parseItems(summaryItems, {
        categoryTitle,
      });
    },
    [summaryItems, categoryTitle]
  );

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Title */}
      {
        title && (
          <div className={classnames(styles.title, titleClassName)}>
            {t(title)}
          </div>
        )
      }

      {/* Product List */}
      <div className={styles.products}>
        <GroupedProductList
          groupClassName={classnames(styles.group, groupClassName)}
          groupHeaderClassName={classnames(styles.groupHeader, groupHeaderClassName)}
          groupTitleClassName={classnames(styles.groupTitle, groupTitleClassName)}
          groupIconClassName={classnames(styles.groupIcon, groupTitleClassName)}
          groupItemClassName={classnames(styles.item, itemClassName)}
          products={items}
          withoutGrouping={withoutGrouping}
          renderProduct={(product: IOrderProduct) => {
            return (
              <OrderProduct
                key={product.id}
                className={productClassName}
                nameClassName={productNameClassName}
                priceClassName={productPriceClassName}
                quantityClassName={productQuantityClassName}
                product={product}
                dataTestId={productDataTestId}
                currency={currency}
                inline={inline}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

// Helpers

const parseItems = (items: IOrderProduct[], data: {categoryTitle?: string}) => {
  const { products, gifts } = items.reduce((acc, item) => {
    if (item.isGift) {
      acc.gifts.push({
        ...item,
        breadcrumbs: [
          {
            name: data?.categoryTitle,
            icon: 'gift',
            groupClassName: styles.gifts,
          }
        ]
      });
    } else {
      acc.products.push(item);
    }
    return acc;
  }, {
    products: [],
    gifts: []
  });
  return [
    ...gifts,
    ...products
  ];
};

// Export

export default OrderProductList;

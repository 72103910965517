import React from 'react';
import classnames from 'classnames';

import * as ColorHelpers from 'helpers/ColorHelpers';
import { useConfig } from 'hooks/data/useConfig';

import type { DataTestId, IOrder } from 'types';

import styles from './OrderStatusBadge.module.scss';

interface Props extends DataTestId {
  className?: string,
  order: IOrder
}

const OrderStatusBadge = (props: Props) => {

  const {
    className,
    order,
    dataTestId
  } = props;

  // Hooks

  const { data: { orders: mappings = {} } = {} } = useConfig();

  const { state } = order || {};

  const { label, color } = React.useMemo(() => {
    const { stateLabel, stateColor } = mappings[state] || {};
    return {
      label: stateLabel,
      color: stateColor
    };
  }, [mappings, order]);

  // Empty

  if (!label || !color) {
    return null;
  }

  // Props

  const bgColor = `rgba(${ColorHelpers.hexToRgb(color).join(',')}, 0.1)`;

  // Render

  return (
    <div
      className={classnames(styles.root, className)}
      style={{
        backgroundColor: bgColor,
        color
      }}
      data-testid={dataTestId}
    >
      {label}
    </div>
  );
};

export default OrderStatusBadge;

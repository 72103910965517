import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import {
  Icon, Image, Link, Price
} from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import * as ProductHelpers from 'helpers/ProductHelpers';
import { useMediaQuery } from 'hooks/useMediaQuery';

import type { DataTestId, IOrderProduct } from 'types';

import styles from './OrderProduct.module.scss';

interface Props extends DataTestId {
  className?: string,
  nameClassName?: string,
  priceClassName?: string,
  quantityClassName?: string,
  product: IOrderProduct,
  currency?: string,
  inline?: boolean
}

const OrderProduct = (props: Props) => {
  const {
    className,
    nameClassName,
    priceClassName,
    quantityClassName,
    currency,
    inline,
    product,
    dataTestId
  } = props;

  const { replacements = [] } = product;

  const hasReplacements = replacements?.length > 0;

  const classes = classnames(
    styles.root,
    { [styles.inline]: inline },
    className
  );

  return (
    <div className={classes} data-testid={dataTestId}>

      {/* Product */}
      <div className={styles.product}>
        <ProductRow
          nameClassName={nameClassName}
          priceClassName={priceClassName}
          quantityClassName={quantityClassName}
          product={product}
          currency={currency}
        />
      </div>

      {/* Replacements */}
      {
        hasReplacements && (
          <div className={styles.replacements}>
            {
              replacements.map((replacement) => (
                <div className={styles.replacement} key={replacement.productSlug}>
                  <div className={styles.icon}>
                    <Icon name="enter" size={24} />
                  </div>
                  <ProductRow
                    nameClassName={classnames(styles.replacementName, { [styles.original]: replacement?.productSlug === product?.productSlug })}
                    quantityClassName={styles.replacementQuantity}
                    product={replacement as IOrderProduct}
                    currency={currency}
                    small
                  />
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

interface ProductRowProps {
  nameClassName?: string,
  priceClassName?: string,
  quantityClassName?: string,
  product: IOrderProduct,
  small?: boolean,
  currency?: string
}

const ProductRow = (props: ProductRowProps) => {
  const {
    nameClassName,
    priceClassName,
    quantityClassName,
    product,
    small,
    currency
  } = props;

  const {
    isGift,
    productSlug,
    productName,
    image,
    quantity,
    priceLabel,
    priceLabelColor,
    subtotal,
    message,
  } = product;

  const { t } = useTranslation();
  const isBreakpoint = useMediaQuery(`(max-width: ${styles.breakpoint})`);

  const IMAGE_SIZE = small
    ? isBreakpoint ? 32 : 40
    : isBreakpoint ? 48 : 64;

  const isProductUnavailable = ProductHelpers.isOrderDetailsProductUnavailable(product);

  const classes = classnames(
    styles.productRow,
    { [styles.small]: small },
    { [styles.unavailable]: isProductUnavailable }
  );

  return (
    <div className={classes}>

      {/* Image */}
      <div className={styles.imageContainer}>
        <Link
          href={`${APP_ROUTES.PRODUCTS}/${productSlug}`}
          prefetch={false}
          passHref
        >
          <a className={styles.imageLink}>
            <Image
              src={image}
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              alt={productName}
            />
          </a>
        </Link>
      </div>

      {/* Content */}
      <div className={styles.content}>

        {/* Details */}
        <div className={styles.details}>

          {/* Name */}
          <div className={classnames(styles.name, nameClassName)}>
            <Link
              href={`${APP_ROUTES.PRODUCTS}/${productSlug}`}
              prefetch={false}
              passHref
            >
              <a>{productName}</a>
            </Link>
          </div>

          {/* Message */}
          {
            message && (
              <div className={styles.message}>
                {message}
              </div>
            )
          }

        </div>

        {/* Quantity / Price */}
        <div className={styles.priceContainer}>

          {/* Quantity */}
          <div className={classnames(styles.quantity, quantityClassName)}>
            {
              (!quantity || isGift)
                ? null
                : `${quantity} ${t('account:ORDER_HISTORY.PRODUCT_UNIT')}`
            }
          </div>

          {/* Price */}
          <div
            className={classnames(
              styles.price,
              priceClassName
            )}
          >
            {
              isProductUnavailable
                ? null
                : (
                  <Price
                    currency={currency}
                    value={subtotal}
                    nullable
                    nullLabel={priceLabel || (isGift ? t('LABELS.FREE') : null)}
                    color={priceLabelColor || (isGift ? styles.colorPrimary : null)}
                  />
                )
            }
          </div>

        </div>
      </div>
    </div>
  );
};

export default OrderProduct;

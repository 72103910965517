import React from 'react';
import { useTranslation } from 'next-i18next';

import { Error, Modal } from 'components/ui';
import {
  OrderReportProblemProvider, useOrderReportProblemDispatchHelpers, useOrderReportProblemStore
} from 'context/OrderReportProblemContext';

import OrderProblemsList from './OrderProblemsList';

import type { IOrder } from 'types';

import styles from './OrderProblemModal.module.scss';

interface Props {
  isOpen: boolean,
  order: IOrder,
  handleClose: () => void
}

const OrderReportProblemModal = (props: Props) => {
  const {
    isOpen,
    order,
    handleClose,
  } = props;

  // Hooks

  const { t } = useTranslation();

  const { showStep } = useOrderReportProblemDispatchHelpers();
  const {
    title: Title, titleProps, component: Component, componentProps, modalProps
  } = useOrderReportProblemStore();

  // Effects

  React.useEffect(() => {
    if (isOpen) {
      showStep({
        title: t('account:ORDER_HISTORY.REPORT_ISSUE_TITLE'),
        titleProps: {},
        component: OrderProblemsList,
        componentProps: {},
        modalProps: {
          contentClassName: styles.selectionModal
        },
      });
    }
  }, [isOpen]);

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
      }}
      //
      title={
        typeof Title === 'string'
          ? Title
          : <Title {...titleProps} />
      }
      //
      close={handleClose}
      {...modalProps}
      dataTestId="order-problem-modal"
    >
      {
        Component
          ? (
            <Component
              order={order}
              closeModal={handleClose}
              {...componentProps}
            />
          ) : (
            <Error />
          )
      }
    </Modal>
  );
};

const withContext = (Component: any) => {
  return (props: any) => {
    return (
      <OrderReportProblemProvider>
        <Component {...props} />
      </OrderReportProblemProvider>
    );
  };
};

export default withContext(OrderReportProblemModal);
